import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { InvestmentsItemContainer } from "./investmentsStyle"
import locationIcon from "../../images/location.png"
import { injectIntl } from "gatsby-plugin-intl"
const InvestmentsItem = ({
  estateName,
  flatSize,
  status,
  estate,
  intl,
  hideNameOnDesktop,
}) => {
  return (
    <InvestmentsItemContainer
      img={estate.img}
      status={status}
      position={estate.position}
    >
      <Container>
        <Row>
          <Col className="image-col" xs="12" />
          <Col className="text-col" xs="12">
            <div className="top">
              <p>
                {status === "inProgress" &&
                  intl.formatMessage({ id: "estate.status.inProgress" })}
                {status === "done" &&
                  intl.formatMessage({ id: "estate.status.done" })}
                {status === "preview" &&
                  intl.formatMessage({ id: "estate.status.preview" })}
                {status === "premiere" &&
                  intl.formatMessage({ id: "estate.status.premiere" })}
                {status === "completed" &&
                  intl.formatMessage({ id: "estate.status.completed" })}
              </p>
              <h3 className={hideNameOnDesktop ? "hideOnDesktop" : ""}>
                {estateName}
              </h3>
              <button disabled={status === "preview"} className="d-xl-none">
                {flatSize}
              </button>
            </div>
            <div className="estate-info d-none d-lg-flex">
              <div className={"content"}>{estate.descriptionDesktop}</div>
              <div className="location">
                <img src={locationIcon} alt=" " />
                <p>{estate.location}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </InvestmentsItemContainer>
  )
}

export default injectIntl(InvestmentsItem)
