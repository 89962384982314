import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl, Link } from "gatsby-plugin-intl"
import InvestmentsItem from "@/components/investements/investmentsItem"
import janowopark2 from "@/images/janowo-2/model_OJP2_ujecie192-2.jpg"
import wiczlino2img from "@/images/z-8/OGWII-inwestycje.jpg"
import puckImage from "@/images/puck/z16-visual-tablet.jpg"

const ProgramInvestments = ({ intl }) => {
  const Puck = {
    name: intl.formatMessage({ id: "estate.name.puck" }),
    sizes: (
      <span>
        {intl.formatMessage({ id: "home.investment.puck.content.mobile" })}
      </span>
    ),
    descriptionDesktop: (
      <p>
        <strong>
          {intl.formatMessage({ id: "home.investment.puck.content.bold" })}
        </strong>
        {intl.formatMessage({ id: "home.investment.puck.content" })}
      </p>
    ),
    location: intl.formatMessage({ id: "estate.city.puck" }),
    img: puckImage,
  }
  const JanowoParkTwo = {
    name: intl.formatMessage({ id: "estate.name.janowoParkTwo" }),
    sizes: (
      <span>
        {intl.formatMessage({ id: "home.investment.janowotwo.content.mobile" })}
        <sup>2</sup>
      </span>
    ),
    descriptionDesktop: (
      <p>
        <strong>
          {intl.formatMessage({ id: "home.investment.janowotwo.content.bold" })}
        </strong>
        {intl.formatMessage({ id: "home.investment.janowotwo.content" })}
      </p>
    ),
    location: intl.formatMessage({ id: "estate.city.rumia" }),
    img: janowopark2,
    position: "80% center",
  }
  const Wiczlino2 = {
    name: intl.formatMessage({ id: "estate.name.wiczlinotwo" }),
    sizes: (
      <span>
        {intl.formatMessage({
          id: "home.investment.wiczlinotwo.content.mobile",
        })}
        <sup>2</sup>
      </span>
    ),
    descriptionDesktop: (
      <p>
        <strong>
          {intl.formatMessage({
            id: "home.investment.wiczlinotwo.content.bold",
          })}
        </strong>
        {intl.formatMessage({ id: "home.investment.wiczlinotwo.content" })}
      </p>
    ),
    location: intl.formatMessage({ id: "estate.city.gdynia" }),
    img: wiczlino2img,
  }

  return (
    <ProgramInvestmentsContainer>
      <h2>Polecane inwestycje biorące udział w programie:</h2>
      <div className="wrapper">
        <Link to={"/oferta/osiedle-pogodne-w-pucku"}>
          <InvestmentsItem
            estateName={Puck.name}
            status="inProgress"
            flatSize={Puck.sizes}
            estate={Puck}
          />
        </Link>
        <Link to={"/oferta/osiedle-gdynia-wiczlino-2"}>
          <InvestmentsItem
            estateName={Wiczlino2.name}
            status="inProgress"
            flatSize={Wiczlino2.sizes}
            estate={Wiczlino2}
          />
        </Link>

        <Link to={"/oferta/osiedle-janowo-park-2"}>
          <InvestmentsItem
            estateName={JanowoParkTwo.name}
            status="inProgress"
            flatSize={JanowoParkTwo.sizes}
            estate={JanowoParkTwo}
          />
        </Link>
      </div>
    </ProgramInvestmentsContainer>
  )
}

export default injectIntl(ProgramInvestments)

export const ProgramInvestmentsContainer = styled.div`
  padding: 40px 0;
  width: 100%;

  h2 {
    margin-bottom: 40px;
  }
  .wrapper {
    display: grid;
    width: 100%;
    gap: 40px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
`
