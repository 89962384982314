import styled from "styled-components"

export const InvestmentsContainer = styled.div`
  font-family: "futura-pt", sans-serif !important;
  background-color: #f8f8f8;
  padding: 86px 0 100px 0;
  .header {
    h1 {
      font-size: 37px;
      margin-bottom: 32px;
    }
    span {
      font-weight: 300;
      display: block;
    }
    p {
      line-height: 27px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 35px;
    }
  }
  .no-padding {
    padding: 0;
  }
  .custom-padding {
    padding: 0;
  }
  @media (min-width: 769px) {
    .custom-padding:nth-of-type(odd) {
      padding-right: 15px;
    }
  }
  @media (min-width: 1200px) {
    .header {
      h1 {
        font-size: 48px;
      }
    }
    .custom-padding:nth-of-type(odd) {
      padding-right: 30px;
    }
    //.custom-padding:nth-last-of-type(2) {
    //  padding: 0 10px;
    //}
    //.custom-padding:nth-last-of-type(1) {
    //  padding-right: 0;
    //  padding-left: 20px;
    //}
    //.custom-padding:nth-last-of-type(3) {
    //  padding-right: 20px;
    //}
  }
`

export const InvestmentsItemContainer = styled.div`
  display: block;
  background-color: white;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.06);

  position: relative;
  color: #000;
  margin-bottom: 35px;
  transition: all 0.2s linear;
  border-radius: 4px;
  overflow: hidden;

  a & {
    cursor: pointer;
  }

  .image-col {
    padding: 0;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: ${props => props.position || "left bottom"};
    width: 100%;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .container,
  .row {
    height: 100%;
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
  }
  .container {
    //position: absolute;
  }
  .text-col {
    padding: 0 20px;
    .top {
      p {
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        margin: 20px 0 14px;
        text-transform: uppercase;
        color: ${props => {
          let color
          color =
            (props.status === "inProgress" && "#94C835") ||
            props.status === "finished" ||
            (props.status === "completed" && "#B5B5B5") ||
            (props.status === "preview" && "#F5A124") ||
            (props.status === "premiere" && "#e3c413")
          return color
        }};
        border: 1px solid
          ${props =>
            props.status === "inProgress"
              ? "#94C835"
              : props.status === "finished" || props.status === "completed"
              ? "#B5B5B5"
              : props.status === "preview" && "#F5A124"};
        padding: 8px 7px;
        border-radius: 4px;
        display: inline-block;
        @media only screen and (max-width: 576px) {
          margin: 20px 0 7px;
          font-size: calc(1200vw / 375);
        }
      }
      h3 {
        font-size: 24px;
        @media only screen and (max-width: 576px) {
          font-size: calc(2400vw / 375);
        }
        @media (min-width: 1200px) {
          font-size: 24px;
          padding: 0 0 10px;
        }
        &.hideOnDesktop {
          @media (min-width: 1200px) {
            display: none;
          }
        }
      }
      button {
        font-size: 16px;
        @media only screen and (max-width: 576px) {
          font-size: calc(1600vw / 375);
        }
        border: none;
        padding: 0;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #dadada;
        background-color: white;
        color: #b0b0b0;
        position: relative;
        margin: 6px 0 15px;
        transition: all 0.3s linear;
        outline: none;
        :hover:not([disabled]) {
          color: #6a6a6a;
        }
        ::after {
          content: "";
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translateY(-50%) rotate(225deg);
          width: 8px;
          height: 8px;
          border-left: #94c835 solid 2px;
          border-bottom: #94c835 solid 2px;
        }
        :before {
          content: "";
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 0%;
          height: 2px;
          background-color: #94c835;
          transition: 0.3s linear all;
        }
        :hover:not([disabled])::before {
          width: 100%;
        }

        &[disabled]::after {
          display: none;
        }
      }
    }
    .estate-info {
      justify-content: space-between;
      align-items: center;
      .content {
        font-size: 15px;
        p {
          font-weight: 400;
          strong {
            font-weight: 600;
            margin-bottom: 0.5em;
            display: block;
          }
        }
      }
      .location {
        width: auto;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        right: 24px;
        margin-top: 24px;
        img {
          height: 22px;
          margin-right: 8px;
        }
        p {
          font-weight: 500;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    height: 600px;
    border-bottom: 4px solid transparent;
    &:hover {
      border-bottom: 4px solid #94c835;
    }
    .row {
      flex-direction: column;
      flex-wrap: nowrap;
      .image-col {
        max-height: 350px;
      }
      .text-col {
        max-height: calc(100% - 350px);
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1440px) {
    //min-height: 480px;
    height: 590px;
    .row {
      .image-col {
        max-height: 300px;
      }
      .text-col {
        max-height: calc(100% - 300px);
      }
    }
    .text-col .top {
      h3 {
        // font-size: 26px;
      }
    }
  }
`
