import React from "react"

import Helmet from "react-helmet"
import Layout from "@/components/layout"

import { HeaderContainer } from "@/components/estates/janowo/janowoStyle"
import EstateTitle from "@/components/estates/estateTitle"
import { Col, Container, Row } from "react-bootstrap"

import img from "@/images/z-9/program-3000.jpg"
import ProgramInfoBar from "@/components/program/programInfoBar"
import ProgramInvestments from "@/components/program/programInvestments"
import { MobileButton } from "@/components/shared/button/button"

const OfferIndexPage = () => (
  <>
    <Helmet>
      <title>WILLBUD | POLEĆ WILLBUD – ODBIERZ 3000zł</title>
    </Helmet>
    <Layout>
      <HeaderContainer>
        <Container>
          <Row>
            <Col className="content-wrapper" xs="12" sm="12" md="12" lg="4">
              <EstateTitle
                mainTitle="Poleć WILLBUD - Odbierz 3000zł"
                subTitleBold="Opinia najbliższych to najlepsza droga do bezpiecznego i satysfakcjonującego zakupu nieruchomości."
                subtitle="Dlatego chcemy nagrodzić każdego, kto poleci innym mieszkanie w inwestycjach Willbud."
              />
            </Col>
            <Col xs="12" sm="12" md="12" lg="8" className="image-wrapper">
              <img
                src={img}
                alt="Poleć mieszkanie w inwestycji Willbud i odbierz 3000zł"
              />
            </Col>
          </Row>
          <Row>
            <ProgramInfoBar />
            <ProgramInvestments />
          </Row>
          <Row style={{ paddingBottom: "40px" }}>
            <Col style={{ paddingLeft: 0 }}>
              <a
                href="/dokumenty/14_06_2023_WILLBUD_REGULAMIN_3000.pdf"
                rel="noopener nofollow"
                target="_blank"
              >
                <MobileButton
                  bg={"#94C835"}
                  innerText="Regulamin programu"
                  color={"#fff"}
                />
              </a>
            </Col>
            <Col style={{ paddingRight: 0 }}>
              <a
                href="/dokumenty/WILLBUD-formularz-3000-17.03.2023.pdf"
                rel="noopener nofollow"
                target="_blank"
              >
                <MobileButton
                  bg={"#5D5D5D"}
                  innerText="Formularz zgłoszeniowy"
                  color={"#fff"}
                />
              </a>
            </Col>
          </Row>
        </Container>
      </HeaderContainer>
    </Layout>
  </>
)

export default OfferIndexPage
